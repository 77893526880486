
import { defineComponent, ref, watch } from "vue";
import ContentModule from "../store/modules/Content/content";

export default defineComponent({
  name: "LanguageSelector",
  props: {
    material: {
      type: Object,
      required: true,
    },
  },
  setup (props, { emit }) {
    const isOpen = ref(false);
    const language = ref(props.material.LanguageCode);
    const languagesList = props.material.LanguagesList;

    watch(() => props.material.LanguageCode, (n) => {
      language.value = n;
    });

    const changeLanguage = async (lang) => {
      const selection = props.material.OtherLanguages.find(l => l.Code === lang);

      ContentModule.changeLanguage({
        material: props.material,
        language: selection,
      });
      emit("onChangeLanguage");
    };

    return { isOpen, language, languagesList, changeLanguage };
  },
});
